import Depressiya from './img/depressiya.svg'
import Motivatsiya from './img/motivatsiya.svg'
import Other from './img/other.svg'
import Otnosheniya from './img/otnosheniya.svg'
import React from 'react'
import Samoocenca from './img/samoocenca.svg'
import Sex from './img/sex.svg'
import Stress from './img/stress.svg'
import Trevojnost from './img/trevojnost.svg'
import Vigoranie from './img/vigoranie.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Grid } from '../../atoms/Grid'
import { Icons } from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { navigate } from 'gatsby'
import { size } from '../../constants'
import { useNewOpenForm } from '../../components/GA'

const cards = [
  {
    badge: {
      title: 'Тревожность',
      icon: <Icons.IconGhost />,
      color: '#FFEC9B'
    },
    title: 'Сомневаюсь во всем и не могу сделать выбор',
    imageSrc: Trevojnost,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=623448a416bc140028aa6831&pt_wrkrs%5B1%5D=623448b116bc140028aa6832&pt_wrkrs%5B2%5D=623448bd16bc140028aa6833&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Отношения',
      icon: <Icons.IconUsers />,
      color: '#FFA494'
    },
    title: 'Не могу построить здоровые отношения',
    imageSrc: Otnosheniya,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=6234473516bc140028aa681c&pt_wrkrs%5B1%5D=6234479c16bc140028aa6820&pt_wrkrs%5B2%5D=62344ab616bc140028aa68bd&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Стресс',
      icon: <Icons.IconSpeedometerMax />,
      color: '#C3DCF6'
    },
    title: 'Не знаю, что делать, много всего навалилось',
    imageSrc: Stress,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=623447dd16bc140028aa6824&pt_wrkrs%5B1%5D=623447ee16bc140028aa6825&pt_wrkrs%5B2%5D=6234489616bc140028aa6830&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Депрессия',
      icon: <Icons.IconBomb />,
      color: '#DADADA'
    },
    title: 'Вообще не чувствую радости в жизни, нет никаких желаний',
    imageSrc: Depressiya,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=623448fc16bc140028aa6837&pt_wrkrs%5B1%5D=6234490a16bc140028aa683f&pt_wrkrs%5B2%5D=6234494316bc140028aa684b&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Выгорание',
      icon: <Icons.IconFire />,
      color: '#FFEC9B'
    },
    title: `Работаю через силу, коллеги стали<br />раздражать`,
    imageSrc: Vigoranie,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=6234482b16bc140028aa6829&pt_wrkrs%5B1%5D=6234488916bc140028aa682f&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Самооценка',
      icon: <Icons.IconFavorite />,
      color: '#C3DCF6'
    },
    title: 'Не могу полюбить себя',
    imageSrc: Samoocenca,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=623449eb16bc140028aa687b&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Мотивация',
      icon: <Icons.IconCoffeeSteam />,
      color: '#C3DCF6'
    },
    title: 'Не могу избавиться от прокрастинации',
    imageSrc: Motivatsiya,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=6234485716bc140028aa682c&pt_wrkrs%5B1%5D=6234486516bc140028aa682d&pt_wrkrs%5B2%5D=62344a0416bc140028aa6886&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Секс',
      icon: <Icons.IconHeartLock />,
      color: '#FFA494'
    },
    title: 'Не можем наладить с партнером интимную жизнь',
    imageSrc: Sex,
    href: '/catalog/?pt_lang=russian&pt_wrkrs%5B0%5D=6234476216bc140028aa681e&pt_wrkrs%5B1%5D=623447cd16bc140028aa6823&pt_wrkrs%5B2%5D=62344aa916bc140028aa68b7&pt_friendliness=false&pt_source_id=UC_12XK08&pt_is_urgent=true&pt_age_min=24&pt_age_max=65'
  },
  {
    badge: {
      title: 'Другое',
      icon: <Icons.IconOther />,
      color: '#DADADA'
    },
    title: 'Мне плохо, но я понятия не имею, что со мной',
    imageSrc: Other,
    href: '/wizard/'
  }
]

const Badge = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 24px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;

  margin-bottom: 8px;

  display: inline-flex;
  width: min-content;
  align-items: center;
`

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.md}) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xxs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const StyledBadgeTitle = styled(Text.Medium)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
`

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  justify-content: space-between;
  overflow: hidden;
  height: 220px;
  min-width: 384px;

  @media (max-width: ${size.lg}) {
    min-width: 304px;
  }

  @media (max-width: ${size.xxs}) {
    min-width: 288px;
    max-width: 288px;
    height: 204px;
  }
`

const CardTitle = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  max-width: 258px;
`

const ImageContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;

  width: 167px;
  height: 154px;

  @media (max-width: ${size.lg}) {
    width: 110px;
    height: 130px;
  }

  @media (max-width: ${size.md}) {
    width: 139px;
    height: 154px;
  }

  @media (max-width: ${size.sm}) {
    width: 167px;
    height: 154px;
  }

  @media (max-width: ${size.xs}) {
    width: 93px;
    height: 106px;
  }
`

const StyledImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
`

const StyledButton = styled(Button.Grey)`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-left: 16px;

  max-width: 173px;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`
const CardTitleWrapper = ({ title }) => (
  <CardTitle dangerouslySetInnerHTML={{ __html: title }} />
)

const Cards = styled(({ className }) => (
  <div className={className}>
    {cards.map((card, i) => (
      <Card key={`card${i}`} onClick={() => navigate(card.href)}>
        <ImageContainer>
          <StyledImage src={card.imageSrc} />
        </ImageContainer>
        <CardBody>
          <Badge color={card.badge.color}>
            {card.badge.icon}
            <StyledBadgeTitle>{card.badge.title}</StyledBadgeTitle>
          </Badge>
          <CardTitleWrapper title={card.title} />
        </CardBody>
        <StyledButton
          href={card.href}
          onClick={useNewOpenForm('requestsExample')}
          size="small"
          type="link"
          width="fullScreen"
        >
          Найти психолога
        </StyledButton>
      </Card>
    ))}
  </div>
))`
  width: 100%;
  padding-top: 48px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${size.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

const FullWidthButton = styled(Button.NewPrimary)`
  width: 100%;
  max-width: 384px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-left: 16px;
  margin-bottom: 120px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
    margin-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }

  @media (max-width: ${size.xxs}) {
    margin-bottom: 32px;
  }
`

export const WorkingAreas = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>С чем работают психологи YouTalk</StyledTitle>
      <Cards />
      <CenteredButtonContainer>
        <FullWidthButton
          href="/wizard/"
          onClick={useNewOpenForm('requestsExample')}
          size="large"
          type="link"
          width="fullScreen"
        >
          Подобрать психолога
        </FullWidthButton>
      </CenteredButtonContainer>
    </Grid>
  </div>
))`
  width: 100%;
  padding-top: 48px;
  position: relative;
  background-color: #dfebf9;

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 56px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 40px;
  }

  & ${Title.H2} {
    position: relative;
    z-index: 3;
  }
`
