import BannerLG from './img/banner/lg.svg'
import BannerMD from './img/banner/md.svg'
import BannerSM from './img/banner/sm.svg'
import BannerXS from './img/banner/xs.svg'
import BannerXXS from './img/banner/xxs.svg'
import React from 'react'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { navigate } from 'gatsby'
import { size } from '../../constants'

const Content = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 8px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${size.lg}) {
    display: none;
  }
`

const MediumImageContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.lg}) {
    display: flex;
  }

  @media (max-width: ${size.md}) {
    display: none;
  }
`

const SmallImageContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.md}) {
    display: flex;
  }

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

const MobileImageContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  @media (max-width: ${size.xs}) {
    display: none;
  }
`

const MobileImageSmallContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.xs}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
`

const StyledImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  @media (max-width: ${size.sm}) {
    margin-top: 24px;
  }
`

export const Banner = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <Content onClick={() => navigate('/wizard/')}>
        <ImageContainer>
          <StyledImage src={BannerLG} />
        </ImageContainer>
        <MediumImageContainer>
          <StyledImage src={BannerMD} />
        </MediumImageContainer>
        <SmallImageContainer>
          <StyledImage src={BannerSM} />
        </SmallImageContainer>
        <MobileImageContainer>
          <StyledImage src={BannerXS} />
        </MobileImageContainer>
        <MobileImageSmallContainer>
          <StyledImage src={BannerXXS} />
        </MobileImageSmallContainer>
      </Content>
    </Grid>
  </div>
))`
  width: 100%;
  position: relative;
  background-color: #dfebf9;
  padding-top: 120px;
  padding-bottom: 120px;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`
