import FirstWhale from './img/firstWhale.svg'
import React from 'react'
import SecondWhale from './img/secondWhale.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Grid } from '../../atoms/Grid'
import { Icons } from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { size } from '../../constants'
import { useNewOpenForm } from '../../components/GA'

const whales = [
  {
    title: 'Профессионализм и&nbsp;специализация',
    text: 'Психологическое образование и владение методом психотерапии — базовые условия для работы психологом.<br /><br />На деле это не всегда так. В России закон почти не регулирует сферу психотерапии — психологом может назвать себя любой, кто прошел краткосрочные курсы. ',
    features: [
      {
        text: 'YouTalk проверяет дипломы о высшем образовании и повышении квалификации, а также опыт работы с указанными запросами.'
      }
    ],
    image: FirstWhale
  },
  {
    title: 'Запрос клиента',
    text: 'Терапия начинается с запроса: клиент сам определяет, каких изменений он хочет достичь в работе с психологом.',
    features: [
      {
        text: 'Понятная цель — это отличный старт: остается построить путь и придерживаться курса.'
      }
    ],
    secondText:
      'Алгоритм YouTalk учитывает запрос клиента и специализацию психолога, чтобы работа шла быстрее и эффективнее. Если человеку сложно сформулировать запрос, задача терапевта — помочь ему в этом.',
    image: SecondWhale
  },
  {
    title: 'Отношения между клиентом и психологом',
    text: 'Со временем между специалистом и клиентом формируется терапевтический альянс. Это тандем, участники которого объединяют усилия для решения проблемы.<br /><br />Для некоторых клиентов это первые отношения в жизни, в которых:',
    features: [
      {
        text: 'они чувствуют себя в безопасности'
      },
      {
        text: 'могут проявлять себя с любой стороны и не быть идеальными'
      },
      {
        text: 'нет запретных тем и неправильных чувств'
      }
    ],
    secondText:
      'Именно такие отношения придают человеку сил и способствуют изменениям в жизни.<br />Терапевтический альянс строится на доверии. YouTalk сотрудничает с психологами, профессионализму которых можно доверять.'
  }
]

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Whale = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  min-width: 384px;

  @media (max-width: ${size.lg}) {
    flex-direction: row;
    min-height: 474px;
    padding-right: 0px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${size.xs}) {
    flex-direction: column;
    min-width: 288px;
  }

  @media (max-width: ${size.xxs}) {
    flex-direction: column;
    max-width: 288px;
  }
`

const WhaleBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${size.lg}) {
    flex-direction: row;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`

const WhaleTitle = styled(Text.Medium)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding-top: 24px;
  padding-bottom: 16px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const WhaleTitleWrapper = ({ title }) => (
  <WhaleTitle dangerouslySetInnerHTML={{ __html: title }} />
)

const WhaleText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
`

const WhaleTextWrapper = ({ title }) => (
  <WhaleText dangerouslySetInnerHTML={{ __html: title }} />
)

const Feature = styled.div`
  display: inline-flex;
  background-color: #dfebf9;
  border-radius: 16px;
  padding: 8px;

  margin-top: 10px;

  @media (max-width: ${size.lg}) {
    align-items: center;
  }

  @media (max-width: ${size.md}) {
    margin-right: 24px;
  }
`
const FeatureIcon = styled.div`
  margin-right: 8px;
`

const FeatureText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 336px;
  height: auto;
  flex-shrink: 0;
  align-self: flex-end;
  overflow: hidden;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    max-width: 230px;
    max-height: 394px;
    margin-left: 8px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 326px;
    max-height: 326px;
    align-self: center;
  }

  @media (max-width: ${size.xs}) {
    max-width: 240px;
    max-height: 326px;
    align-self: center;
  }
`

const StyledImage = styled.img`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (max-width: ${size.lg}) {
    width: 345px;
    left 85px;
  }

  @media (max-width: ${size.md}) {
    left 0;
    width: 280px;
    height: auto;
  }
  @media (max-width: ${size.sm}) {
    bottom 0;
    width: 326px;
    height: auto;
  }

  @media (max-width: ${size.xs}) {
    bottom 0;
    width: 240px;
    height: auto;
  }
`

const WhaleContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;

  @media (max-width: ${size.lg}) {
    width: 100%;
  }
`

const StyledIndexBlock = styled.div`
  display: flex;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  background-color: #2963a3;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  margin-top: 24px;
`

const StyledIndexText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  align-self: center;
`

// eslint-disable-next-line max-lines-per-function
const Whales = styled(({ className }) => (
  <div className={className}>
    {whales.map((whale, i) => (
      <Whale key={`whale${i}`}>
        <WhaleBody>
          <WhaleContent>
            <StyledIndexBlock>
              <StyledIndexText>{i + 1}</StyledIndexText>
            </StyledIndexBlock>
            <WhaleTitleWrapper title={whale.title} />
            <WhaleTextWrapper title={whale.text} />
            {whale.features.map((feature, j) => (
              <Feature key={`whale${j}`}>
                <FeatureIcon>
                  <Icons.IconCheck />
                </FeatureIcon>
                <FeatureText>{feature.text}</FeatureText>
              </Feature>
            ))}
            {whale.secondText && <WhaleTextWrapper title={whale.secondText} />}
          </WhaleContent>
          {whale.image && (
            <ImageContainer>
              <StyledImage src={whale.image} />
            </ImageContainer>
          )}
        </WhaleBody>
      </Whale>
    ))}
  </div>
))`
  width: 100%;
  padding-top: 48px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${size.lg}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: ${size.md}) {
    padding-top: 32px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xxs}) {
    padding-top: 16px;
  }
`

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

const FullWidthButton = styled(Button.NewPrimary)`
  width: 100%;
  max-width: 384px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-left: 16px;
  margin-bottom: 120px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
    margin-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }

  @media (max-width: ${size.xxs}) {
    margin-bottom: 32px;
  }
`

export const ThreeWhales = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>Три кита психотерапии</StyledTitle>
      <Whales />
      <CenteredButtonContainer>
        <FullWidthButton
          href="/wizard/"
          onClick={useNewOpenForm('requestsExample')}
          size="large"
          type="link"
          width="fullScreen"
        >
          Подобрать своего психолога
        </FullWidthButton>
      </CenteredButtonContainer>
    </Grid>
  </div>
))`
  width: 100%;
  padding-top: 48px;
  position: relative;
  background-color: #dfebf9;

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 56px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 40px;
  }
`
