import FirstFormat from './img/firstFormat.svg'
import React from 'react'
import SecondFormat from './img/secondFormat.svg'
import ThirdFormat from './img/thirdFormat.svg'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { WizardTherapyType } from '../../atoms/mappers/wizard/enums/therapyType'
import { navigate } from 'gatsby'
import { size } from '../../constants'

const formats = [
  {
    title: 'Один на один',
    text: 'Индивидуальная психотерапия, где участвуют двое: клиент и психолог',
    price: 'от 2 000 ₽',
    image: FirstFormat,
    type: WizardTherapyType.Individual
  },
  {
    title: 'Парный',
    text: 'К психологу обращаются партнеры, чтобы разобраться в отношениях друг с другом',
    price: 'от 3 000 ₽',
    image: SecondFormat,
    type: WizardTherapyType.FamilyPartner
  },
  {
    title: 'Вместе с ребенком',
    text: 'Психолог работает с ребенком и взрослым родственником',
    price: 'от 3 000 ₽',
    image: ThirdFormat,
    type: WizardTherapyType.FamilyChild
  }
]

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 48px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
  }
`

const Format = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #dfebf9;
  border-radius: 16px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  height: 100%;
`

const FormatBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  @media (max-width: ${size.md}) {
    flex-direction: row;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`

const FormatTitle = styled(Text.Medium)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding-top: 24px;
  padding-bottom: 16px;

  @media (max-width: ${size.xs}) {
    font-size: 20px;
    padding-top: 16px;
    padding-bottom: 12px;
  }
`

const FormatTitleWrapper = ({ title }) => (
  <FormatTitle dangerouslySetInnerHTML={{ __html: title }} />
)

const FormatText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const FormatPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const FormatPrice = styled(Text.Medium)`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @media (max-width: ${size.xs}) {
    font-size: 28px;
    line-height: 36px;
  }
`

const FormatPriceCaption = styled(Text.Medium)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  opacity: 0.5;

  @media (max-width: ${size.xs}) {
    font-size: 14px;
    line-height: 20px;
  }
`

const FormatTextWrapper = styled.div`
  flex-grow: 1;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 24px;

  @media (max-width: ${size.md}) {
    width: 50%;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 300px;

  @media (max-width: ${size.lg}) {
    max-width: 280px;
  }

  @media (max-width: ${size.md}) {
    max-width: 240px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 300px;
  }

  @media (max-width: ${size.xs}) {
    max-width: 240px;
  }
`

const FormatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: space-between;

  @media (max-width: ${size.md}) {
    width: 50%;
    padding-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 0px;
    width: 100%;
  }
`

const Formats = styled(({ className }) => (
  <div className={className}>
    {formats.map((format, i) => (
      <Format
        key={`format${i}`}
        onClick={() =>
          navigate('/wizard/', { state: { choosedFormat: format.type } })
        }
      >
        <FormatBody>
          <FormatContent>
            <FormatTitleWrapper title={format.title} />
            <FormatTextWrapper>
              <FormatText>{format.text}</FormatText>
            </FormatTextWrapper>
            <FormatPriceWrapper>
              <FormatPrice>{format.price}</FormatPrice>
              <FormatPriceCaption>за одну сессию</FormatPriceCaption>
            </FormatPriceWrapper>
          </FormatContent>
          <ImageContainer>
            <StyledImage src={format.image} />
          </ImageContainer>
        </FormatBody>
      </Format>
    ))}
  </div>
))`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: ${size.md}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const TherapyFormats = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>Форматы психотерапии</StyledTitle>
      <Formats />
    </Grid>
  </div>
))`
  width: 100%;
  padding-top: 48px;
  position: relative;

  margin-bottom: 60px;

  @media (max-width: ${size.md}) {
    margin-bottom: 36px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 56px;
    margin-bottom: 36px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 40px;
  }
`
