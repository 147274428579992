import React from 'react'
import SessionsOnline from './img/sessionsOnline.svg'
import SessionsOnlineMD from './img/sessionsOnlineMD.svg'
import psyAdvantages from './img/psyAdvantages.svg'
import psyImage from './img/psyImage.svg'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;

  max-width: 1050px;

  @media (max-width: ${size.md}) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xxs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-top: 48px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 8px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${size.md}) {
    display: none;
  }
`

const MediumImageContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.md}) {
    display: flex;
  }

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

const MobileImageContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
`

const StyledImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  @media (max-width: ${size.sm}) {
    margin-top: 24px;
  }
`

export const SessionsInfo = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>Сессии с психологами YouTalk проходят онлайн</StyledTitle>
      <Content>
        <ImageContainer>
          <StyledImage src={SessionsOnline} />
        </ImageContainer>
        <MediumImageContainer>
          <StyledImage src={SessionsOnlineMD} />
        </MediumImageContainer>
      </Content>
      <MobileImageContainer>
        <StyledImage src={psyAdvantages} />
        <StyledImage src={psyImage} />
      </MobileImageContainer>
    </Grid>
  </div>
))`
  width: 100%;
  position: relative;
  background-color: #dfebf9;
  padding-top: 120px;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`
