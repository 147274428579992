import FirstStep from './img/firstStep.png'
import FourthStep from './img/fourthStep.png'
import React from 'react'
import SecondStep from './img/secondStep.png'
import ThirdStep from './img/thirdStep.png'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Grid } from '../../atoms/Grid'
import { Icons } from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'
import { useNewOpenForm } from '../../components/GA'

const steps = [
  {
    title: 'Заполните анкету. Несколько вопросов, которые займут 6 минут',
    text: 'Укажите ключевые моменты:',
    features: [
      {
        text: 'Как вы себя чувствуете последнее время?'
      },
      {
        text: 'С чем нужна помощь — например, с карьерой, отношениями или переживанием потери?'
      },
      {
        text: 'Важен ли вам пол и возраст психолога, а также подход, в котором работает специалист?'
      }
    ],
    image: FirstStep,
    button: 'Заполнить анкету'
  },
  {
    title: 'Алгоритм подберет подходящих специалистов',
    text: 'Изучите анкеты и выберите психолога, с которым хотите начать терапию.',
    image: SecondStep
  },
  {
    title: 'Выберите дату и время первой сессии',
    text: "Зарегистрируйтесь на платформе YouTalk при помощи номера телефона и оплатите первую сессию — это гарантирует, что выбранное время у специалиста будет зарезервировано для вас.<br /><br /><a href='/sposoby-oplaty/'>Принимаются</a> российские и зарубежные карты.<br /><br />Сразу после оплаты мы отправим вам SMS или сообщение в WhatsApp с памяткой для подготовки к первой сессии.",
    image: ThirdStep,
    button: 'Начать терапию'
  },
  {
    title: 'Управляйте расписанием в личном кабинете',
    text: 'Выбирайте дату и время сессии, оплачивайте и переносите встречи.',
    image: FourthStep
  }
]

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Step = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #dfebf9;
  border-radius: 16px;
  overflow: hidden;
  min-width: 384px;

  margin-bottom: 48px;

  @media (max-width: ${size.lg}) {
    flex-direction: row;
    min-height: 474px;
    padding-right: 0px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  @media (max-width: ${size.xs}) {
    flex-direction: column;
    min-width: 288px;
    margin-bottom: 16px;
  }

  @media (max-width: ${size.xxs}) {
    flex-direction: column;
    max-width: 288px;
  }
`

const StepBody = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: ${(props) => props.flexDirection};

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`

const StepTitle = styled(Text.Medium)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding-top: 24px;
  padding-bottom: 16px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const StepTitleWrapper = ({ title }) => (
  <StepTitle dangerouslySetInnerHTML={{ __html: title }} />
)

const StepText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
`

const StepTextWrapper = ({ title }) => (
  <StepText dangerouslySetInnerHTML={{ __html: title }} />
)

const Feature = styled.div`
  display: inline-flex;
  background-color: #dfebf9;
  border-radius: 16px;
  padding: 8px;

  margin-top: 10px;

  @media (max-width: ${size.lg}) {
    align-items: center;
  }

  @media (max-width: ${size.md}) {
    margin-right: 24px;
  }
`
const FeatureIcon = styled.div`
  margin-right: 8px;
`

const FeatureText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
`

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 100%;
`

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
  width: 100%;
  justify-content: center;
  @media (max-width: ${size.sm}) {
    margin-bottom: 0px;
  }
`

const StyledIndexBlock = styled.div`
  display: flex;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  background-color: #2963a3;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
`

const StyledIndexText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  align-self: center;
`

const StyledButton = styled(Button.NewPrimary)`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  align-items: center;
  margin-top: 24px;
  justify-content: space-between;
  + @media (max-width: ${size.lg}) {
    margin-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
    margin-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }

  @media (max-width: ${size.xxs}) {
    margin-bottom: 32px;
  }
`

// eslint-disable-next-line max-lines-per-function
const Steps = styled(({ className }) => (
  <div className={className}>
    {steps.map((step, i) => (
      <Step key={`step${i}`}>
        <StepBody flexDirection={i % 2 === 0 ? 'row' : 'row-reverse'}>
          <StepContent>
            <StyledIndexBlock>
              <StyledIndexText>{i + 1}</StyledIndexText>
            </StyledIndexBlock>
            <StepTitleWrapper title={step.title} />
            <StepTextWrapper title={step.text} />
            {step?.features &&
              step.features.map((feature, j) => (
                <Feature key={`step${j}`}>
                  <FeatureIcon>
                    <Icons.IconCheck />
                  </FeatureIcon>
                  <FeatureText>{feature.text}</FeatureText>
                </Feature>
              ))}
            {step.secondText && <StepTextWrapper title={step.secondText} />}
            {step.button && (
              <StyledButton
                href="/wizard/"
                onClick={useNewOpenForm('requestsExample')}
                size="large"
                type="link"
                width="fullScreen"
              >
                {step.button}
                <Icons.IconArrowRight />
              </StyledButton>
            )}
          </StepContent>
          {step.image && (
            <ImageContainer>
              <StyledImage src={step.image} />
            </ImageContainer>
          )}
        </StepBody>
      </Step>
    ))}
  </div>
))`
  width: 100%;
  padding-top: 48px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${size.md}) {
    padding-top: 32px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xxs}) {
    padding-top: 16px;
  }
`

export const HowTo = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>Как начать терапию с психологом YouTalk</StyledTitle>
      <Steps />
    </Grid>
  </div>
))`
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`
