import './index.scss'
import React from 'react'
import { contacts } from '../../../static/db.json'

const Telephone = ({ href, phone, desc, link, linkDesc }) => (
  <div className="contacts-tel__block">
    <a className="contacts-tel__number" href={href}>
      {phone}
    </a>
    {link ? (
      <p className="contacts-tel__desc">
        {desc}&nbsp;{link && <a href={link}>{linkDesc}</a>}
      </p>
    ) : (
      <p className="contacts-tel__desc">{desc}</p>
    )}
  </div>
)

const HomeContacts = () => (
  <section className="home-contacts">
    <div className="container container-addition">
      <div className="row">
        <div className="designer-col col-12 col-md-6 col-lg-8">
          <p className="home-contacts__title">Экстренная помощь</p>
          <p className="home-contacts__desc">
            В ситуации, угрожающей жизни, обращайтесь:
          </p>
          <ul className="home-contacts__phones contacts-phones">
            {contacts.map((contact) => (
              <li
                key={contact.href}
                className="contacts-phones__tel contacts-tel"
              >
                <Telephone {...contact} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    <img
      alt="Две руки, тянущиеся друг к другу, почти соприкоснулись ладонями"
      className="home-contacts__img"
      src="../img/icons/beacon.svg"
    />
  </section>
)

export default HomeContacts
