import Planet from './img/planet.svg'
import React from 'react'
import WomanWithNote from './img/womanWithNote.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { size } from '../../constants'
import { useNewOpenForm } from '../../components/GA'

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #2963a3;
  color: white;
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    padding-top: 280px;
    padding-bottom: 24px;
  }
`

const LeftImageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  @media (max-width: ${size.md}) {
    width: 165px;
    heigth: 193px;
  }

  @media (max-width: ${size.sm}) {
    width: 93px;
    heigth: 148px;
  }
`

const MainImageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  width: 316px;
  heigth: 297px;

  @media (max-width: ${size.md}) {
    top: 24px;
    left: 50%;
    transform: translateX(-50%);

    width: 232px;
    heigth: 232px;
  }
`

const StyledImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`

const StyledMainImage = styled.img`
  position: relative;
  top: 30px;
  left: 54px;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (max-width: ${size.md}) {
    top: 0;
    left: 0;
  }
`

const StyledTitle = styled(Text.Medium)`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 8px;
  text-align: center;
  color: white;

  @media (max-width: ${size.sm}) {
    margin-top: 24px;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    margin-top: 24px;
    font-size: 18px;
    line-height: 26px;
  }
`

const StyledDesc = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 24px;

  text-align: center;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const FullWidthButton = styled(Button.NewPrimary)`
  @media (max-width: ${size.md}) {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }
`

const TitleWrapper = ({ title }) => (
  <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
)

export const BannerWithButton = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <Content>
        <LeftImageContainer>
          <StyledImage src={Planet} />
        </LeftImageContainer>
        <MainImageContainer>
          <StyledMainImage src={WomanWithNote} />
        </MainImageContainer>
        <TitleWrapper title="Психотерапия — это инструмент,<br />который делает жизнь гармоничнее" />
        <StyledDesc>от 2 000 ₽ за сессию 50 минут</StyledDesc>
        <FullWidthButton
          href="/wizard/"
          onClick={useNewOpenForm('requestsExample')}
          size="large"
          type="link"
          width="fullScreen"
        >
          Подобрать психолога
        </FullWidthButton>
      </Content>
    </Grid>
  </div>
))`
  width: 100%;
  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;

  @media (max-width: ${size.lg}) {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 12px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 16px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 32px;
  }
`
