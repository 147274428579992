import FirstAward from './img/firstAward.svg'
import FourthAward from './img/fourthAward.svg'
import React from 'react'
import SecondAward from './img/secondAward.svg'
import ThirdAward from './img/thirdAward.svg'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { size } from '../../constants'

const awards = [
  {
    text: 'Победитель Social Impact Award 2018 в номинации Ростелеком «Интернет для лучшего мира»',
    image: FirstAward
  },
  {
    text: 'Призер конкурса МТС «Social Idea» в 2019 году',
    image: SecondAward
  },
  {
    text: 'Шорт-лист премии Forbes Woman Mercury Awards — 2021',
    image: ThirdAward
  },
  {
    text: 'Первое место в конкурсе стартапов AdVentureLand Pitch Challenge на Startup Village 2021',
    image: FourthAward
  }
]

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const AwardText = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  width: 100%;
  display: flex;
`

const AwardTextWrapper = ({ title }) => (
  <AwardText dangerouslySetInnerHTML={{ __html: title }} />
)

const Award = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  max-height: 150px;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-height: 220px;
  }
`

const StyledImage = styled.img`
  display: flex;
  margin-right: 24px;

  @media (max-width: ${size.lg}) {
    margin-right: 0px;
    margin-bottom: 24px;
    max-height: 74px;
  }
`

const Awards = styled(({ className }) => (
  <div className={className}>
    {awards.map((award, i) => (
      <Award key={`award${i}`} className={`award${i + 1}`}>
        <StyledImage src={award.image} />
        <AwardTextWrapper title={award.text} />
      </Award>
    ))}
  </div>
))`
  width: 100%;
  padding-top: 48px;
  display: grid;
  gap: 24px;
  grid-template-areas:
    'award1 award1 award1 award2'
    'award3 award4 award4 award4';

  .award1 {
    grid-area: award1;
  }

  .award2 {
    grid-area: award2;
  }

  .award3 {
    grid-area: award3;
  }

  .award4 {
    grid-area: award4;
  }

  @media (max-width: ${size.lg}) {
    grid-template-areas:
      'award1 award2'
      'award3 award4';
    padding-top: 32px;
  }

  @media (max-width: ${size.md}) {
    grid-template-areas:
      'award1 award1 award1 award2'
      'award3 award3 award4 award4';
    padding-top: 32px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'award1'
      'award2'
      'award3'
      'award4';
    padding-top: 24px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xxs}) {
    padding-top: 16px;
  }
`

export const OurAwardsNew = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>Наши премии</StyledTitle>
      <Awards />
    </Grid>
  </div>
))`
  width: 100%;
  position: relative;
  padding-bottom: 120px;
  background-color: #dfebf9;

  @media (max-width: ${size.lg}) {
    padding-bottom: 120px;
  }

  @media (max-width: ${size.md}) {
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 48px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 32px;
  }
`
