import qs from 'qs'

export const composeSearchParams = (params) => {
  if (Array.isArray(params) && !params.length) {
    return ''
  }
  if (Array.isArray(params)) {
    const searchParams = params.filter((value) => value).join('&')
    return `?${searchParams}`
  }
  return typeof params === 'string' && params ? `?${params}` : ''
}

export const setSearchQueryCommaFormat = (query) =>
  qs.stringify(query, { arrayFormat: 'comma' })

export const getSearchQuery = (search) =>
  qs.parse(search, {
    ignoreQueryPrefix: true
  })
