import { composeSearchParams } from './composeSearchParams'
import { getInitialParamsHowSearchQuery } from './initialQueryParams'
import { useLocation } from '@reach/router'
import { useMemo } from 'react'

export const usePsychologistCardHref = (psychologistId, params) => {
  const location = useLocation()

  return useMemo(() => {
    const isProd = process.env.NODE_ENV === 'production'
    ///need for local norm redirect
    const url = isProd ? process.env.GATSBY_SITEURL : location.origin

    return `${url}/psychologist/${psychologistId}/${composeSearchParams([
      getInitialParamsHowSearchQuery(),
      params ?? ''
    ])}`
  }, [psychologistId, params])
}
