import React from 'react'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { selectPartnersLogo } from '../../state/reducers/partnersSlice'
import { size } from '../../constants'
import { useNewMediaPartners } from '../../components/GA'
import { useSelector } from 'react-redux'

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Partner = styled.li`
  width: 100%;
  list-style: none;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 48px;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const PartnersBody = styled.ul`
  width: 100%;
  padding-top: 48px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: ${size.sm}) {
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }
`

const Partners = styled(({ className, partnersLogo }) => {
  const handleNewMediaPartners = useNewMediaPartners()
  return (
    <>
      {!partnersLogo.isLoading && partnersLogo.partners.length !== 0 && (
        <PartnersBody className={className}>
          {partnersLogo.partners.map(({ url, imgUrl, imgAlt }, i) => (
            <Partner key={i}>
              <a
                href={url}
                onClick={handleNewMediaPartners}
                rel="noreferrer"
                target="_blank"
              >
                <img alt={imgAlt} src={imgUrl} />
              </a>
            </Partner>
          ))}
        </PartnersBody>
      )}
    </>
  )
})`
  width: 100%;
  padding-top: 48px;

  @media (max-width: ${size.md}) {
    padding-top: 32px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 24px;
  }

  @media (max-width: ${size.xxs}) {
    padding-top: 16px;
  }
`

export const PartnersBlock = styled(({ className }) => {
  const partnersLogo = useSelector(selectPartnersLogo)
  return (
    <div className={className}>
      <Grid>
        <StyledTitle>Про нас пишут</StyledTitle>
        <Partners partnersLogo={partnersLogo} />
      </Grid>
    </div>
  )
})`
  width: 100%;
  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;
  background-color: #dfebf9;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
    padding-bottom: 120px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 16px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 32px;
  }
`
